export default function api(data = {}){
    return new Promise((resolve)=>{
    let xmlhttp=new XMLHttpRequest();
    if(data && data.method === 'POST'){
        xmlhttp.open(data.method,data.url,true)
        xmlhttp.send(`tel=${data.account}&password=${data.password}`)
    } else {
        xmlhttp.open(data.method,`${data.url}?tel=${data.account}&password=${data.password}`,true)
        xmlhttp.send()
    }
    xmlhttp.onreadystatechange = function(){
        
       if(xmlhttp.readyState == 4){
        resolve(JSON.parse(xmlhttp.responseText))
       }
    }
    }).catch((e) => {
       console.log(e)
    })

    
}
<template>
  <div class="content">
    <header-nav></header-nav>
    <div class="zcp">
      <div class="sigbox">
        <div class="sigimg">
          <img :src="require('@/assets/images/sigicon.png')" alt="" />
        </div>
        <div class="msgbox">
          <div class="bigw">会员登录</div>
          <div class="warning"> <el-alert  v-if="showalert" class="warnBox" :title="warnTxt" type="warning" show-icon>
  </el-alert></div>
          <div class="inputcon">
            <el-input
              placeholder="手机号"
              prefix-icon="el-icon-user"
              v-model="loginForm.account"
              class="text-box"
            >
            </el-input>
          </div>
          <div class="inputcon">
            <el-input
              placeholder="密码" show-password
              prefix-icon="el-icon-lock"
              v-model="loginForm.password"
              class="text-box"
            >
            </el-input>
          </div>
          <div class="inputBtn">
            <el-button type="primary" @click="submit" round class="btn-box">登录</el-button>
          </div>
          <div class="nopsw">
            <div class="check">
              <el-checkbox v-model="loginForm.remember">记住密码</el-checkbox>
            </div>
            <div class="l-w"><a href="https://www.lanmuda.com.cn/blueye2/xlq_modify.jsp">忘记密码？</a></div>
          </div>
          <div class="inputBtn zc">
            <el-button class="btn-box" round @click="toSignup">立即注册</el-button>
          </div>
          <div class="last-w" @click="toSignup">
            Don't have an account ? <span href="" style="color: #3560fa; cursor: pointer;"> Register</span>
          </div>
        </div>
      </div>
    </div>

    <floor></floor>
  </div>
</template>

<script>
import request from "../common/request.js"
const Base64 = require('js-base64').Base64;
import HeaderNav from "@/components/Header.vue";
import Floor from "@/components/Floor.vue";
export default {
  components: {
    HeaderNav,
    Floor,
  },
  data() {
    return {
      isLogin: false,
      showalert:false,
      warnTxt:'',
      loginForm: {
        account: "",
        password: "",
        remember: false,
      },
    };
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val) {
        if (val) {
          this.setCookie('account', ' ', -1) 
          alert('已退出登录')
          this.isLogin = this.getCookie("account");
          // this.$router.go(-1);
        }
      },
    },
  },
  created() {
    this.isLogin = this.getCookie("account");
    let account = this.getCookie("account");
    let password = Base64.decode(this.getCookie("password"));
    if (account) {
      this.loginForm.account = account;
      this.loginForm.password = password;
      this.loginForm.remember = true;
    }
  },
   methods: {
        toSignup(){
           window.location.href = 'https://www.lanmuda.com.cn/blueye2/xlq_signup.jsp';
        },
        // 登录
        submit () {
          let myreg=/^[1][3,4,5,7,8,9][0-9]{9}$/;
          if (!myreg.test(this.loginForm.account)) {
              // alert("请填写正确的手机号码");
              this.showalert = true
              this.warnTxt = '请填写正确的手机号码'
              return
          } 
          if(!this.loginForm.account || !this.loginForm.password){
            this.showalert = true
            this.warnTxt = '请输入正确的账号密码'
            return 
          }
          // let data = `del=${this.loginForm.account}&password=${this.loginForm.password}`
          // let xmlhttp = new XMLHttpRequest()
          let data = {
            url: 'https://crm.lanmuda.com.cn/UserGetMyInfo',
            method: "GET",
            account: this.loginForm.account,
            password: this.loginForm.password
          }

          request(data).then(res=>{
            if(res.code===1){
              this.setUserInfo()
              this.$router.go(-1);
            } else if(res.code===-3){
              alert('由于恶意刷邀请,您的账号已被冻结，如有问题请找客服！')
            } else {
              this.showalert = true
              this.warnTxt = '账号或密码错误'
            }
          }).catch(err=>{
            console.log('error',err)
          })
        },
        // 储存表单信息
        setUserInfo: function () {
            // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
            // 如果没有勾选，储存的信息为空
            this.setCookie("account",this.loginForm.account)
            if(this.loginForm.remember){
                // base64加密密码
                let passWord = Base64.encode(this.loginForm.password)
                this.setCookie("password",passWord)    
            }else{
                this.setCookie("password","") 
            } 
        },
        // 获取cookie
        getCookie: function (key) {
            if (document.cookie.length > 0) {
            var start = document.cookie.indexOf(key + '=')
            if (start !== -1) {
                start = start + key.length + 1
                var end = document.cookie.indexOf(';', start)
                if (end === -1) end = document.cookie.length
                return unescape(document.cookie.substring(start, end))
            }
            }
            return false
        },
        // 保存cookie
        setCookie: function (cName, value, expiredays) {
            var exdate = new Date()
            exdate.setDate(exdate.getDate() + expiredays)
            document.cookie = cName + '=' + decodeURIComponent(value) +
            ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
        },

    }
};
</script>

<style lang="less" scoped>
.content {
  .zcp {
    flex: 1;
    min-height: 800px;
    width: 100%;
    background-image: url("../assets/images/sigbj.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .sigbox {
      width: 1150px;
      height: 680px;
      margin: 100px 0;
      background: #ffffff;
      border-radius: 80px;
      box-shadow: -10px -10px 15px rgba(15, 59, 237, 0.5);
      display: flex;
      padding: 120px 120px 120px 0;
      box-sizing: border-box;
      .sigimg {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .msgbox {
        // position: relative;
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 30px 60px;
        box-sizing: border-box;
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        .bigw {
          width: 100%;
          padding-bottom: 20px;
          text-align: center;
          font-size: 26px;
        }
        .warning{
          position: relative;
          height: 30px;
          width: 100%;
          .warnBox{
            position: absolute;
            width: 100%;
            height: 30px;
            text-align: center;
           /deep/ .el-alert__closebtn{
              top:10px
            }
          }
        }
        .inputcon {
          width: 100%;
          padding: 10px 0;
          .text-box {
            /deep/ input {
              background: #f7f7f7;
              border: none;
              height: 30px;
              border-radius: 15px;
            }
            /deep/ i {
              line-height: 30px;
            }
          }
        }
        .inputBtn {
          width: 100%;
          padding-top: 10px;
          .btn-box {
            width: 100%;
            height: 30px;
            padding: 0;
            /deep/ .el-button--primary {
              width: 100%;
            }
          }
        }
        .zc {
          padding-top: 40px;
        }
        .nopsw {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          .check {
            font-size: 10px;
          }
          .l-w {
            font-size: 12px;
            a {
              text-decoration: none;
              color: #000000;
            }
          }
        }
      }
      .last-w {
       
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        width: 100%;
        padding-top: 10px;
      }
    }
  }
}

</style>